<template>
  <div>
    <app-filter :filter-config="carouselsFilterConfig"
                @filtersChanged="fetchData" />
    <div class="carousels-content-wrapper fluid-container">
      <div class="carousels-wrapper">
        <div v-if="$route.params.companyId"
             class="carousels">
          <div class="carousels-headline">
            <h1 class="carousels-headline-text">
              {{ 'carousels_page_title'|translate }}
            </h1>
          </div>
          <draggable v-model="carousels"
                     v-bind="dragOptions"
                     class="carousels-container">
            <div v-for="carousel in carousels"
                 :key="carousel.id"
                 class="carousel">
              <div class="carousel-image">
                <img src="@/assets/images/carousels.png"
                     alt="carousels"
                     class="carousel-image-thumb">
              </div>
              <div class="carousel-content">
                <div class="carousel-title">
                  {{ carousel.title }}
                </div>
                <div v-if="!dragOptions.disabled"
                     class="carousel-grab g-icon g-icon-grab" />
                <div class="carousel-controls">
                  <div class="carousel-buttons">
                    <div class="carousel-edit"
                         @click="showModal(carousel)">
                      <span class="g-icon g-icon-edit" />
                      <span class="carousel-edit-label">
                        {{ 'edit_modal_edit_title'|translate }}
                      </span>
                    </div>
                    <div class="carousel-delete"
                         @click="showDeletePrompt(carousel.id)">
                      <span class="g-icon g-icon-trash" />
                      <span class="carousel-edit-label">
                        {{ 'delete_label'|translate }}
                      </span>
                    </div>
                  </div>
                  <div class="carousel-statuses">
                    <div class="carousel-status">
                      <span class="g-icon g-icon-circle-b"
                            :class="{'disabled-orange' : !carousel.active}" />
                      <label class="carousel-status-label">
                        {{ 'edit_modal_active_label'|translate }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-empty"
                 @click="showModal()">
              <span class="g-icon g-icon-more-sports" />
              <span class="carousel-empty-label">{{ 'add_carousel_label'|translate }} </span>
            </div>
          </draggable>
        </div>
      </div>
    </div>
    <carousel-modal :is-modal-open.sync="isModalOpen" />
    <delete-modal :is-modal-open.sync="isModalOpen" />
  </div>
</template>

<script>

import draggable from 'vuedraggable';
import { mapActions, mapGetters, mapState } from 'vuex';
import AppFilter from '../components/AppFilter';
import CarouselModal from '../components/CarouselModal';
import DeleteModal from '../components/shared/DeleteModal';

export default {
  name: 'Carousels',
  components: {
    AppFilter,
    draggable,
    CarouselModal,
    DeleteModal,
  },
  data() {
    return {
      isModalOpen: false,
      dragOptions: {
        forceFallback: true,
        fallbackTolerance: 3,
        scrollSensitivity: 200,
        scrollSpeed: 20,
        disabled: false,
        handle: '.carousel-grab',
      },
    };
  },
  computed: {
    ...mapState({
      filterParams: (state) => state.filterParams,
    }),
    ...mapGetters(['carouselsFilterConfig', 'casinoCarousels']),
    carousels: {
      get() {
        return this.casinoCarousels;
      },
      set(value) {
        this.toggleLoader(true);

        this.updateCasinoCarouselsOrder({
          companyId: this.$route.params.companyId,
          order: value.map((carousel) => carousel.id),
        }).finally(() => this.toggleLoader(false));
      },
    },
  },
  methods: {
    ...mapActions([
      'toggleLoader',
      'updateCasinoCarouselsOrder',
      'getCasinoCarousels',
      'getCasinoCarouselTypes',
    ]),
    async fetchData(params = this.filterParams) {
      if (!params.companyId) return;
      this.toggleLoader(true);

      try {
        await this.getCasinoCarousels(params);
        await this.getCasinoCarouselTypes(params);
      } finally {
        this.toggleLoader(false);
      }
    },

    showModal(carousel) {
      const submitAction = !carousel
        ? 'createCasinoCarousel'
        : 'updateCasinoCarousel';
      this.$modal.show('carousel-modal', {
        carousel,
        submitAction,
      });
    },

    showDeletePrompt(carouselId) {
      const submitAction = 'deleteCasinoCarousel';

      this.$modal.show('delete-modal', {
        objectId: carouselId,
        submitAction,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.carousels-content-wrapper {
  --carousel-height: 6rem;
}

.carousels-headline-text {
  font-size: $fs-300;
  font-weight: bold;
  text-transform: uppercase;
  color: $font-primary-1;
  margin-bottom: 0.625rem;
  margin-top: 1rem;
}

.carousels {
  margin-top: 1.25em;
}

.carrousels-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.625em;
  padding-bottom: 1.25em;

  @include breakpoint-medium {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint-large {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.carousel-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--carousel-height);
  margin-bottom: 0.625em;
  color: $font-primary-1;
  font-size: 1em;
  border: 1px dashed $bg-400;
  border-radius: $br-sm;
  background-color: transparent;
  box-shadow: none;

  &-label{
    margin-left: 0.25em;
  }
}

.carousel {
  position: relative;
  height: var(--carousel-height);
  display: flex;
  border-radius: $br-sm;
  background-color: #fff;
  box-shadow: $shadow-outline;
  margin-bottom: 10px;

  &-image {
    position: relative;
    width: 160px;
    background-color: $bg-300;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &-thumb {
      width: 160px;
    }
  }

  &-content {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0.25em 0.75em;
    color: $font-primary-2;

    .carousel-title {
      margin-top: 0.25em;
      font-size: $fs-500;
    }
  }

  &-grab {
    position: absolute;
    top: 15px;
    right: 24px;
    transform: rotate(90deg);
    cursor: move;
    @include increaseClickability(0.75rem);
  }

  &-controls {
    display: flex;
    margin-top: auto;

    .g-icon {
      vertical-align: middle;
      cursor: pointer;
      transition: color 200ms $ease-out-cubic;
      font-size: 20px;

      &:hover {
        color: darken($font-primary-3, 30%);
      }
    }
  }

  &-statuses {
    margin-left: auto;
    display: flex;
    align-items: center;

    .carousel-status {
      text-align: center;

      .g-icon {
        color: $positive;
        vertical-align: bottom;

        &.disabled-orange {
          color: $warning;
        }
      }

      .carousel-status-label {
        display: block;
        font-size: $fs-200;
      }
    }
  }

  &-buttons {
    display: flex;
    margin-right: 0.75rem;

    .carousel-delete,
    .carousel-edit {
      text-align: center;
      margin-left: 1.5em;
    }

    .carousel-edit {
      padding-right: 0.625rem;

      &-label {
        display: block;
        font-size: $fs-200;
      }
    }

    .carousel-delete {
      border-left: thin solid $font-primary-3;
      padding-left: 0.62rem;
    }
  }
}
</style>
