<template>
  <modal name="delete-modal"
         height="auto"
         @before-open="beforeOpen">
    <div class="modal-content-wrapper">
      <div class="modal-header">
        <div class="modal-header-title">
          {{ 'confirm_action' | translate }}
        </div>
        <div class="modal-header-close"
             @click="cancelUpdate()">
          <span class="g-icon g-icon-close-a" />
        </div>
      </div>
      <div class="modal-content">
        <div class="modal-message">
          {{ 'delete_prompt' | translate }}
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="g-button g-button-earth"
              @click="cancelUpdate()">
        <span class="g-button-title">{{ 'edit_modal_cancel_edit' | translate }}</span>
      </button>
      <button
        class="g-button g-button-earth"
        @click="confirmAction()">
        <span class="g-button-title">{{
          'confirm_label' | translate
        }}</span>
      </button>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'DeleteModal',
  data: () => ({
    objectId: null,
  }),
  methods: {
    cancelUpdate() {
      this.isDropdownOpen = false;
      return this.$modal.hide('delete-modal');
    },

    beforeOpen(event) {
      this.submitAction = event.params.submitAction;
      this.objectId = event.params.objectId;
    },

    confirmAction() {
      const { companyId } = this.$route.params;

      this.$store.dispatch(this.submitAction, {
        objectId: this.objectId,
        companyId,
      }).then(() => {
        this.$modal.hide('delete-modal');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../../styles/components/_modal";

</style>
