<template>
  <modal name="carousel-modal"
         height="auto"
         scrollable
         :adaptive="true"
         @before-open="beforeOpen">
    <div class="modal-content-wrapper">
      <div class="modal-header">
        <div class="modal-header-title">
          {{ 'edit_modal_edit_title' | translate }}
        </div>
        <div class="modal-header-close"
             @click="cancelUpdate()">
          <span class="g-icon g-icon-close-a" />
        </div>
      </div>
      <div class="modal-content">
        <v-container fluid>
          <v-row dense>
            <v-select v-model="carousel.type"
                      :items="parsedCarouselTypes"
                      :label="selectedType ? $options.filters.translate(selectedType.name) :
                        $options.filters.translate('carousel_type_label')"
                      outlined
                      :disabled="isEditMode"
                      class="rounded-0"
                      @change="updateSelectedType()" />
          </v-row>
          <modal-input
            key="carouselTitle"
            v-model="carousel.title"
            class="modal-input"
            :label="$options.filters.translate('carousel_title_label')"
            name="carouselTitle" />
          <modal-input
            key="numberOfGames"
            v-model="carousel.numberOfGames"
            class="modal-input"
            :label="$options.filters.translate('carousel_number_of_games_label')"
            type="number"
            name="numberOfGames" />
          <app-toggle v-model="carousel.active"
                      name="carousel_active"
                      :label="$options.filters.translate('edit_modal_active_label')" />
        </v-container>
      </div>
      <div class="modal-footer">
        <button class="g-button g-button-earth"
                @click="cancelUpdate()">
          <span class="g-button-title">{{ 'edit_modal_cancel_edit' | translate }}</span>
        </button>
        <button
          class="g-button g-button-earth"
          @click="submitCarousel()">
          <span class="g-button-title">{{
            'confirm_label' | translate
          }}</span>
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import ModalInput from '@/components/shared/ModalInput';
import { mapGetters } from 'vuex';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import AppToggle from './shared/AppToggle';

export default {
  name: 'CarouselModal',
  components: {
    ModalInput,
    AppToggle,
  },
  data: () => ({
    carousel: {},
    parsedCarouselTypes: [],
    emptyCarousel: {
      type: null,
      title: '',
      numberOfGames: '',
    },
    submitAction: '',
    isEditMode: true,
    selectedType: {},
  }),
  computed: {
    ...mapGetters([
      'casinoCarouselTypes',
      'translation',
      'getCarouselTypeByName',
      'getCarouselTypeById',
    ]),
    carouselTypes() {
      return this.casinoCarouselTypes;
    },
  },
  methods: {
    cancelUpdate() {
      this.isDropdownOpen = false;
      return this.$modal.hide('carousel-modal');
    },
    beforeOpen(event) {
      const { carousel, submitAction } = event.params;

      this.submitAction = submitAction;
      this.parsedCarouselTypes = this.carouselTypes.map((type) => (
        {
          text: this.translation(type.name),
          value: type,
        }
      ));
      this.isEditMode = !_isEmpty(carousel);
      this.carousel = this.isEditMode ? _cloneDeep(carousel) : _cloneDeep(this.emptyCarousel);
      this.updateSelectedType();
    },
    submitCarousel() {
      const { companyId } = this.$route.params;

      this.carousel.typeId = this.selectedType.id;

      this.$store.dispatch(this.submitAction, {
        carousel: this.carousel,
        companyId,
      }).then(() => {
        this.$modal.hide('carousel-modal');
      });
    },
    updateSelectedType() {
      if (this.isEditMode) {
        this.selectedType = this.getCarouselTypeByName(this.carousel.type);
      } else {
        this.selectedType = this.getCarouselTypeById(this.carousel.type?.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "../styles/components/_modal";

.modal-row-container {
  position: relative;
}

.row--dense {
  margin: -2px;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px;
}

.v-label {
  color: #2196f3 !important;
}
</style>
